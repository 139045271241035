import React from 'react'
import { Box } from '@material-ui/core'

//? Own imports
import { Page } from 'components'
import { AccessControl } from 'components/Utils'
import AccessDenied from 'views/Errors/AccessDenied'
import { Filters, Table } from './components'

const MemberList = () => {
  return (
    <Page title="Listagem de Membros">
      <AccessControl
        rule={'membros.list'}
        // @ts-ignore
        yes={() => (
          <Box>
            <Filters />
            <Table />
          </Box>
        )}
        // @ts-ignore
        no={() => <AccessDenied />}
      />
    </Page>
  )
}

export default MemberList
