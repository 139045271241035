import { crudService } from '../_services/'
import { toast } from 'react-toastify'

export const loteAction = {
  getLote,
  addLote,
  getLoteById,
  onChangeProps,
  editLoteInfo,
  editLotesDetails,
  createLote,
  deleteLoteById,
  clear,
  clearAll,
  getLotesByEvento,
  onChangeData,
  openModalTipoDeIngresso,
  onSwitchValor,
  setRepassarTaxaByEstabelecimento
}
function getLote() {
  return (dispatch) => {
    let apiEndpoint = 'lotes'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeLotesList(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
function createLote(payload) {
  return (dispatch) => {
    let apiEndpoint = 'lotes/'
    crudService.post(apiEndpoint, payload).then(() => {
      dispatch(createLoteInfo())
      dispatch(clear())
      dispatch(getLotesByEvento(payload.eventoId))
    })
  }
}
function getLoteById(id) {
  return (dispatch) => {
    let apiEndpoint = 'lotes/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editLotesDetails(response.data))
    })
  }
}

function getLotesByEvento(eventoId) {
  return (dispatch) => {
    let apiEndpoint = 'lotes/evento/' + eventoId
    crudService.get(apiEndpoint).then((response) => {
      dispatch(changeLotesListEvento(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function onSwitchValor(event) {
  return (dispatch) => {
    if (event.target.checked) {
      dispatch(handleOnChangeProps('valor', 0))
    } else {
      dispatch(handleOnChangeProps('valor', ''))
    }
  }
}

function setRepassarTaxaByEstabelecimento(repassarTaxaDefault) {
  return (dispatch) => {
    if (repassarTaxaDefault !== null) {
      dispatch(handleOnChangeProps('repassarTaxa', repassarTaxaDefault))
    }
  }
}

function editLoteInfo(id, payload) {
  return (dispatch) => {
    let apiEndpoint = 'lotes/' + id
    crudService.put(apiEndpoint, payload).then(() => {
      dispatch(updatedLoteInfo())
      dispatch(clear())
      dispatch(getLotesByEvento(payload.eventoId))
    })
  }
}
function deleteLoteById(id, eventoId) {
  return (dispatch) => {
    let apiEndpoint = 'lotes/' + id
    crudService.deleteDetail(apiEndpoint).then(() => {
      dispatch(deleteLotesDetails())
      dispatch(getLotesByEvento(eventoId))
    })
  }
}
function onChangeData(prop, newValue) {
  return (dispatch) => {
    dispatch(handleOnChangeProps(prop, newValue))
  }
}

export function addLote(payload) {
  return [{ type: 'ADD_LOTE', lote: payload }, clear()]
}
export function changeLotesList(lote) {
  return {
    type: 'FETCHED_ALL_LOTE',
    lote: lote
  }
}
export function openModalTipoDeIngresso(open) {
  return {
    type: 'OPEN_MODAL_TIPO_DE_INGRESSO',
    open: open
  }
}
export function changeLotesListEvento(lote) {
  return {
    type: 'FETCHED_ALL_LOTE_EVENTO',
    lote: lote
  }
}
export function clear(repassarTaxa) {
  return {
    type: 'LOTE_CLEAR',
    repassarTaxa: repassarTaxa
  }
}
export function clearAll() {
  return {
    type: 'LOTE_CLEAR_ALL'
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'LOTE_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editLotesDetails(lote) {
  return {
    type: 'LOTE_DETAIL',
    id: lote.id,
    descricao: lote.descricao,
    tipoIngressoId: lote.tipoIngressoId,
    eventoId: lote.eventoId,
    valor: lote.valor,
    quantidade: lote.quantidade,
    limiteQuantidadePedido: lote.limiteQuantidadePedido,
    repassarTaxa: lote.repassarTaxa,
    genero: lote.genero,
    dataHoraTermino: lote.dataHoraTermino,
    dataHoraInicio: lote.dataHoraInicio,
    disponivel: lote.disponivel,
    excluido: lote.excluido,
    checkIn: lote.checkIn,
    planoId: lote.planoId
  }
}
export function updatedLoteInfo() {
  return {
    type: 'LOTE_UPDATED'
  }
}
export function createLoteInfo() {
  toast.success('Lote inserido com sucesso! 👍', {
    position: 'bottom-right'
  })
  return {
    type: 'LOTE_CREATED_SUCCESSFULLY'
  }
}
export function deleteLotesDetails() {
  return {
    type: 'DELETED_LOTE_DETAILS'
  }
}
