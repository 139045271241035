import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import ReactToPrint from 'react-to-print'
import { CSVLink } from 'react-csv'
import { toast } from 'react-toastify'
import {
  TableContainer,
  TableHead,
  TableCell,
  Tooltip,
  Paper,
  TableRow,
  TableBody,
  Avatar,
  IconButton,
  colors,
  Table as TableMUI,
  makeStyles,
  Box,
  Typography,
  CircularProgress,
  TablePagination,
  Backdrop,
  Toolbar,
  TableSortLabel
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import EditIcon from '@material-ui/icons/Edit'
import PrintRoundedIcon from '@material-ui/icons/PrintRounded'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'

import { format } from 'date-fns'

//? Own components
import { Label } from 'components'
import { AccessControl } from 'components/Utils'
import { Member } from 'types/api'
import {
  getImageUrls,
  getInitials,
  cpfCnpjMask,
  phoneMask,
  history,
  useSearchQuery
} from 'utils'
import constants from 'theme/constants'
import { useMemberList } from 'hooks-querys'

const useStyles = makeStyles((theme) => ({
  paper: {
    ...constants.shadowCard
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  rootToolbarStyles: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  titleToolbarStyles: {
    flex: '1 1 100%'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}))

const prepareDataForCSV = (membersList: Member[]) => {
  return membersList.map((row) => ({
    matricula: !!row?.membroPlano
      ? row?.membroPlano[0]?.matricula
        ? row?.membroPlano[0]?.matricula
        : row?.membroPlano[0]?.id
      : '',
    nome: row?.nome,
    email: row?.email,
    cpfCnpj: cpfCnpjMask(row?.cpfCnpj),
    phone: phoneMask(row?.telefone),
    dataNascimento: row?.dataNascimento
      ? format(new Date(row?.dataNascimento), 'dd/MM/yyyy')
      : '',
    dataHoraInicio: row?.membroPlano
      ? format(new Date(row?.membroPlano[0]?.dataHoraInicio), 'dd/MM/yyyy')
      : '',
    plano: row?.membroPlano ? row?.membroPlano[0]?.plano?.nome : '',
    status: row?.membroPlano?.[0]?.statusMembroPlano?.descricao || 'INDEFINIDO'
  }))
}

const headers = [
  { label: 'N.º de Matrícula', key: 'matricula' },
  { label: 'Nome', key: 'nome' },
  { label: 'Email', key: 'email' },
  { label: 'CPF/CNPJ', key: 'cpfCnpj' },
  { label: 'Telefone', key: 'phone' },
  { label: 'Data de Nascimento', key: 'dataNascimento' },
  { label: 'Data de Início', key: 'dataHoraInicio' },
  { label: 'Plano', key: 'plano' },
  { label: 'Status', key: 'status' }
]
type Order = 'asc' | 'desc'
interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  order: Order
  orderBy: string
}
interface HeadCell {
  id: string
  label: string
  align?: 'right' | 'left'
  enableSort: boolean
}

const headCells: HeadCell[] = [
  {
    id: 'id',
    label: 'Matrícula',
    align: 'left',
    enableSort: true
  },
  { id: 'nome', label: 'Nome', enableSort: true },
  { id: 'cpfCnpj', label: 'CPF/CNPJ', enableSort: false },
  {
    id: 'plano',
    label: 'Plano',
    enableSort: true
  },
  { id: 'status', label: 'Status', enableSort: true },
  {
    id: 'actions',
    label: 'Ações',
    align: 'right',
    enableSort: false
  }
]

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props
  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property)
  }
  return (
    <TableHead>
      <TableRow>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'center'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              disabled={!headCell?.enableSort}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id as string)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const Table = () => {
  const classes = useStyles()
  const estabelecimentoId = useSelector(
    // @ts-ignore: //ainda não foi tipado o redux state
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const querySearch = useSearchQuery()

  const order = (querySearch.get('direction') || 'asc') as 'asc' | 'desc'
  const orderBy = querySearch.get('order') || 'nome'

  const { data, isLoading, isFetching } = useMemberList({
    estabelecimentoId: Number(estabelecimentoId || 0),
    planoId: querySearch.get('planoId'),
    statusMembroPlanoId: querySearch.get('statusId'),
    term: querySearch.get('busca') || undefined,
    limit: Number(querySearch.get('limit') || 15),
    page: Number(querySearch.get('page') || 0),
    order: orderBy,
    direction: order
  })

  const refTable = useRef(null)
  const csvData = prepareDataForCSV(data?.items || [])

  const handleDownload = () => {
    toast.success('Download concluído com sucesso! 👍', {
      position: 'top-right'
    })
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    querySearch.set('direction', isAsc ? 'desc' : 'asc')
    querySearch.set('order', property)
    history.push({ search: querySearch.toString() })
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    querySearch.set('page', newPage?.toString())
    history.push({ search: querySearch.toString() })
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    querySearch.set('limit', event.target.value)
    querySearch.set('page', '0')
    history.push({ search: querySearch.toString() })
  }

  if (isLoading) {
    return (
      <Box marginY={4}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box mb={2}>
            <Typography variant="h6">Carregando dados...</Typography>
          </Box>
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box paddingTop={4}>
      <Backdrop className={classes.backdrop} open={isFetching}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Typography variant="body2" align="left" gutterBottom>
        {data && data?.totalRows > 0 ? (
          <>
            Total de dados encontrados: <strong>{data?.totalRows}</strong>
          </>
        ) : (
          <strong>Nenhum dado encontrado.</strong>
        )}
      </Typography>
      {data && data?.totalRows > 0 ? (
        <Paper ref={refTable} className={classes.paper}>
          <Toolbar className={classes.rootToolbarStyles}>
            <Typography
              className={classes.titleToolbarStyles}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Lista de membros
            </Typography>
            <CSVLink
              data={csvData}
              headers={headers}
              target="_blank"
              filename={'lista-de-membros.csv'}
              onClick={() => {
                handleDownload()
              }}
            >
              <Tooltip title="Exportar em CSV">
                <IconButton aria-label="exportar em csv">
                  <GetAppRoundedIcon />
                </IconButton>
              </Tooltip>
            </CSVLink>
            <ReactToPrint
              trigger={() => (
                <Tooltip title="Imprimir tabela">
                  <IconButton aria-label="gerar relatorio">
                    <PrintRoundedIcon />
                  </IconButton>
                </Tooltip>
              )}
              content={() => refTable.current}
            />
          </Toolbar>
          {/* usado para 'forçar' a orientação da pagina para 'paisagem' afim de imprimir mostrando todos os dados */}
          <style type="text/css" media="print">
            {'\
             @page { size: landscape; }\
            '}
          </style>
          {/* ------------------------------------------------------------>	 */}
          <TableContainer>
            <TableMUI>
              {/* @ts-ignore */}
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {data?.items?.map((n: Member) => {
                  return (
                    <TableRow hover key={n.id}>
                      <TableCell align="left" component="th" scope="row">
                        {!!n.membroPlano
                          ? n.membroPlano[0].matricula
                            ? n.membroPlano[0].matricula
                            : n.membroPlano[0].id
                          : ''}
                      </TableCell>
                      <TableCell align="center">
                        {n.nome ? (
                          <Tooltip title={'Acessar detalhes do membro'}>
                            <div
                              onClick={() =>
                                n.membroPlano &&
                                history.push(
                                  `/members/memberPlanDetails/${n.membroPlano[0]?.id}`
                                )
                              }
                              className={classes.nameCell}
                            >
                              <Avatar
                                className={classes.avatar}
                                src={getImageUrls(n?.imagem)?.thumbnailUrl}
                              >
                                {getInitials(n?.nome)}
                              </Avatar>
                              <div>
                                {n?.nome}
                                <div>{n?.email}</div>
                              </div>
                            </div>
                          </Tooltip>
                        ) : (
                          'Usuário não encontrado'
                        )}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {cpfCnpjMask(n.cpfCnpj)}
                      </TableCell>
                      {/* <TableCell align="center" component="th" scope="row">
                    {phoneMask(n.telefone)}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {n.dataNascimento
                      ? format(new Date(n.dataNascimento), 'dd/MM/yyyy')
                      : ''}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {n.membroPlano
                      ? format(
                          new Date(n.membroPlano[0].dataHoraInicio),
                          'dd/MM/yyyy'
                        )
                      : ''}
                  </TableCell> */}
                      <TableCell align="center" component="th" scope="row">
                        {n.membroPlano ? n.membroPlano[0].plano?.nome : ''}
                      </TableCell>

                      <TableCell align="center" component="th" scope="row">
                        <div>
                          <Label
                            color={
                              n.membroPlano &&
                              n.membroPlano[0].statusMembroPlano?.ativo
                                ? colors.green[600]
                                : colors.red[600]
                            }
                          >
                            {n?.membroPlano?.[0]?.statusMembroPlano
                              ?.descricao || 'INDEFINIDO'}
                          </Label>
                        </div>
                      </TableCell>

                      <TableCell align="right">
                        <AccessControl
                          rule={'membros.edit'}
                          // @ts-ignore
                          yes={() => (
                            <IconButton
                              size="small"
                              aria-label="MemberEdit"
                              component="a"
                              onClick={() =>
                                history.push(`/members/cadastroMembro/${n.id}`)
                              }
                            >
                              <Tooltip title="Editar">
                                <EditIcon />
                              </Tooltip>
                            </IconButton>
                          )}
                        />
                        <AccessControl
                          rule={'membros.edit'}
                          // @ts-ignore
                          yes={() => (
                            <IconButton
                              size="small"
                              aria-label="MemberDetails"
                              component="a"
                              onClick={() =>
                                n.membroPlano &&
                                history.push(
                                  `/members/memberPlanDetails/${n.membroPlano[0]?.id}`
                                )
                              }
                            >
                              <Tooltip title="Informações">
                                <InfoOutlinedIcon />
                              </Tooltip>
                            </IconButton>
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </TableMUI>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[
              5,
              15,
              25,
              50,
              100,
              { label: 'Todos', value: 9999 }
            ]}
            component="div"
            count={data?.totalRows}
            rowsPerPage={Number(querySearch.get('limit')) || 15}
            page={Number(querySearch.get('page')) || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : null}
    </Box>
  )
}

export default Table
