import { useQuery } from 'react-query'

//? Own imports
import { SelectListItem } from 'types/api'
import { crudService } from '_services'

const getEstablishmentPlansSelect = async (estabelecimentoId: number) => {
  const apiEndpoint = `planos/select/estabelecimento/${estabelecimentoId}`
  try {
    const { data } = await crudService.get(apiEndpoint)
    return data
  } catch (e) {
    return e
  }
}

export default function useEstablishmentPlansSelect(estabelecimentoId: number) {
  return useQuery<SelectListItem[]>(
    ['establishmentPlansSelect', estabelecimentoId],
    () => getEstablishmentPlansSelect(estabelecimentoId),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      refetchOnWindowFocus: false
    }
  )
}
