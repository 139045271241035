import React from 'react'
import { makeStyles } from '@material-ui/core'
//? Own imports

import { SelectFilters, TableIngressos } from './components'
import { AccessControl } from 'components/Utils/AccessControl'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  }
}))

const TicketsList = () => {
  const classes = useStyles()

  return (
    <AccessControl
      rule={'ingressos.list'} //permissão necessária para acessar conteúdo
      // @ts-ignore
      yes={() => (
        <div className={classes.root}>
          <SelectFilters />
          <TableIngressos />
        </div>
      )}
    />
  )
}

export default TicketsList
