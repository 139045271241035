import React, { useEffect, useState } from 'react'
import { albumAction } from '_actions'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  MenuItem,
  Button,
  Tooltip,
  Grid,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  FormControl,
  useMediaQuery,
  TextField,
  CircularProgress,
  Switch,
  useTheme,
  Chip,
  Typography,
  makeStyles,
  Box,
  Fab,
  Link
} from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getImageUrls, toLocalDateFormat, useDidMountEffect } from 'utils'
import { history } from 'utils'
import { HelperToolTip, Page } from 'components'
import { useParams } from 'react-router-dom'
import constants from 'theme/constants'
import { Autocomplete } from '@material-ui/lab'
import {
  useEvent,
  useEvents,
  useMutationPhotoAlbumCreate,
  useMutationPhotoAlbumEdit,
  usePhotoAlbumId
} from 'hooks-querys'
import { Foto, PhotoAlbum } from 'types/api'
import { PhotoList } from './components'
import { ImagePreviewClientSide } from './components/PhotoList/PhotoList'
import { useConfirm } from 'material-ui-confirm'

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(2)
  },
  formControl: {
    width: '100%'
  },
  paper: {
    ...constants.shadowCard
  },
  containerPhotos: {
    ...constants.shadowCard,
    marginTop: theme.spacing(2)
  },
  fabButton: {
    width: '100%'
  },
  helperContainer: {
    display: 'flex',
    alignItems: 'center'
  }
}))

// @ts-ignore
const PhotoAlbumEdit = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const confirm = useConfirm()
  const mutationCreatePhotoAlbum = useMutationPhotoAlbumCreate()
  const mutationEditPhotoAlbum = useMutationPhotoAlbumEdit()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [eventoEditId, setEventoEditId] = useState()
  // @ts-ignore
  const [selectedEvento, setSelectedEvento] = useState<Evento>({} as Evento)
  const [eventoInputValue, setEventoInputValue] = useState('')
  const [showPastEvents, setShowPastEvents] = useState(true)
  const estabelecimentoId = useSelector(
    // @ts-ignore: //ainda não foi tipado o redux state
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const {
    foto,
    enabledSortableContext
  }: {
    foto: ImagePreviewClientSide[]
    enabledSortableContext: boolean
  } = useSelector(
    // @ts-ignore
    (state) => state.album
  )

  // @ts-ignore
  const { eventoId, photoAlbumId } = useParams()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const { fotoCapaId }: { fotoCapaId: Number } = useSelector(
    // @ts-ignore
    (state) => state.album
  )

  const {
    data: events,
    isLoading: isLoadingEvents,
    isFetching: isFetchingEvents
  } = useEvents(estabelecimentoId, showPastEvents, true)
//} = useEvents(estabelecimentoId, showPastEvents, isMobile)

  // Past Events Shortcut
  const { data: eventQuery } = useEvent(Number(eventoEditId) | eventoId)

  // Edit Photo Album
  const { data: photoAlbumEdit } = usePhotoAlbumId(Number(photoAlbumId))

  useEffect(() => {
    return () => {
      dispatch(albumAction.deleteAllFoto())
    }
  }, [])

  const LoadEditDetails = () => {
    if (!!photoAlbumEdit && foto && foto.length === 0) {
      setTitle(photoAlbumEdit.nome)
      setDescription(photoAlbumEdit.descricao)
      setEventoEditId(photoAlbumEdit.eventoId)
      dispatch(albumAction.setFotoCapaId(photoAlbumEdit.fotoCapaId))
      const files: ImagePreviewClientSide[] = photoAlbumEdit.foto.map(
        (fotoEdit: Foto) => {
          return {
            file: null,
            id: fotoEdit.id,
            name: fotoEdit?.titulo,
            title: fotoEdit?.titulo,
            description: fotoEdit?.descricao,
            // readableSize: filesize(fotoEdit.size),
            preview: getImageUrls(fotoEdit.imagem)?.thumbnailUrl,
            uploaded: true,
            isError: false,
            url: getImageUrls(fotoEdit.imagem)?.originalUrl,
            isCover: fotoEdit.id === photoAlbumEdit.fotoCapaId && true,
            retry: false
          }
        }
      )
      dispatch(albumAction.addFoto(files))
    }
  }

  useEffect(() => {
    LoadEditDetails()
  }, [photoAlbumEdit])

  const toggleEnabledSortableContext = () => {
    dispatch(albumAction.toggleEnabledSortableContext())
  }
  const handleChangeTitle = (value: string) => {
    setTitle(value)
  }

  const handleChangeDescription = (value: string) => {
    setDescription(value)
  }

  const handleRetryAll = () => {
    foto.forEach((item) => {
      if (item.isError) {
        dispatch(
          albumAction.updateFoto({
            ...item,
            retry: true
          })
        )
      }
    })
  }

  const handleCancel = () => {
    if (!!title || !!description || selectedEvento?.id || eventQuery?.id) {
      confirm({
        title: !!photoAlbumId
          ? 'Você deseja cancelar a edição do álbum?'
          : 'Você deseja cancelar o cadastro do álbum?',
        // description: '',
        confirmationText: 'Sim, cancelar!',
        cancellationText: 'Não!'
      }).then(() => {
        dispatch(albumAction.deleteAllFoto())
        history.goBack()
      })
    } else {
      dispatch(albumAction.deleteAllFoto())
      history.goBack()
    }
  }

  const handleSubmit = () => {
    let hasNotUploaded = false
    let fotoPayload: Array<Foto> = []

    foto.map((row) => {
      if (!row.uploaded) {
        hasNotUploaded = true
      }
    })

    if (title === '') {
      toast.error('Você precisa dar um titulo ao álbum!')
    } else if (!selectedEvento?.id && !eventQuery?.id) {
      toast.error('Você precisa selecionar um evento!')
    } else if (foto.length === 0) {
      toast.error('Você precisa adicionar ao menos uma foto!')
    } else {
      confirm({
        title: hasNotUploaded
          ? 'Há fotos com upload incompleto, deseja continuar?'
          : !!photoAlbumId
          ? `Atualizar álbum ${title}?`
          : `Salvar álbum ${title}?`,
        description:
          hasNotUploaded &&
          'Se prosseguir essas fotos não aparecerão no álbum..',
        confirmationText: hasNotUploaded
          ? `Sim, salvar álbum mesmo assim!`
          : 'Sim!',
        cancellationText: 'Não!'
      }).then(() => {
        foto.forEach((item) => {
          if (item?.uploaded) {
            fotoPayload.push({
              id: item.file === null ? Number(item.id) : 0,
              albumId: !!photoAlbumId ? photoAlbumId : 0,
              titulo: !!item.title ? item?.title : '',
              descricao: item?.description,
              ordem: foto.indexOf(item) + 1,
              imagem: !!item.url ? item.url : '',
              capa: !!item.isCover ? item?.isCover : false,
              albumCapa: []
            })
          }
        })

        let payload = {
          id: !!photoAlbumId ? photoAlbumId : 0,
          nome: title,
          descricao: description,
          estabelecimentoId: estabelecimentoId,
          eventoId: selectedEvento?.id > 0 ? selectedEvento.id : eventQuery?.id,
          foto: fotoPayload
        }

        if (photoAlbumId) {
          editPhotoAlbum(payload)
        } else {
          createPhotoAlbum(payload)
        }
      })
    }
  }

  const createPhotoAlbum = (payload: PhotoAlbum) => {
    mutationCreatePhotoAlbum.mutateAsync(payload).then(() => {
      history.push('/photoAlbum/lista')
      dispatch(albumAction.deleteAllFoto())
    })
  }

  const editPhotoAlbum = (payload: PhotoAlbum) => {
    mutationEditPhotoAlbum.mutateAsync(payload).then(() => {
      history.push('/photoAlbum/lista')
      dispatch(albumAction.deleteAllFoto())
    })
  }

  return (
    <Page
      title={
        photoAlbumId
          ? 'Gerência de Álbuns de Fotos - Editar Álbum de Fotos'
          : 'Gerência de Álbuns de Fotos - Novo Álbum de Fotos'
      }
    >
      <Grid item md={12} xs={12}>
        <Card className={classes.paper}>
          <form>
            <CardHeader
              subheader={'Adicione o título e descrição'}
              title={'1 - Informações sobre o álbum'}
            />
            <ValidatorForm id="formphotoAlbum" onSubmit={handleSubmit}>
              <Divider />
              <CardContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <Autocomplete
                        loading={isLoadingEvents}
                        id="combo-box-evento"
                        options={events || []}
                        value={
                          eventQuery != undefined ? eventQuery : selectedEvento
                        }
                        disabled={eventoId || photoAlbumId}
                        onChange={(event, newValue, reason) => {
                          setSelectedEvento(newValue)
                          if (reason === 'clear') {
                            setEventoInputValue('')
                          }
                        }}
                        getOptionLabel={(evento) =>
                          evento.nome &&
                          `${evento.nome} ${
                            evento.dataHoraInicio
                              ? ` - (${toLocalDateFormat(
                                  evento.dataHoraInicio
                                )})`
                              : ''
                          }`
                        }
                        placeholder="Selecione o evento..."
                        onInputChange={(event, newInputValue) => {
                          if (newInputValue !== null) {
                            setEventoInputValue(newInputValue)
                          } else {
                            setEventoInputValue('')
                          }
                        }}
                        inputValue={eventoInputValue}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Selecione o evento"
                            placeholder="Selecione um evento..."
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {!isMobile && (
                                    <InputAdornment position="end">
                                      <>
                                        {isFetchingEvents && (
                                          <CircularProgress
                                            style={{ marginRight: 10 }}
                                            size={20}
                                            color="primary"
                                          />
                                        )}
                                        {/* <Switch
                                          color="primary"
                                          onChange={(event) => {
                                            setShowPastEvents(event.target.checked)
                                          }}
                                          checked={showPastEvents}
                                          size="small"
                                          inputProps={{
                                            'aria-label': 'Listar eventos encerrados'
                                          }}
                                        />
                                        <Chip
                                          variant="default"
                                          size="small"
                                          label="Listar eventos encerrados"
                                          color={showPastEvents ? 'primary' : 'default'}
                                          onClick={() => {
                                            setShowPastEvents(!showPastEvents)
                                          }}
                                        /> */}
                                      </>
                                    </InputAdornment>
                                  )}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <TextValidator
                      name={'title'}
                      variant="outlined"
                      id="nome"
                      label="Título para o álbum *"
                      value={title}
                      onChange={(event) =>
                        // @ts-ignore
                        handleChangeTitle(event.target.value)
                      }
                      validators={['required']}
                      errorMessages={['Campo obrigatório!']}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment position="end">
                              <>
                                {title.length > 200 ? (
                                  <Typography
                                    variant="body2"
                                    align="right"
                                    color="error"
                                  >
                                    {title.length}/200
                                  </Typography>
                                ) : (
                                  <Typography variant="body2" align="right">
                                    {title.length}/200
                                  </Typography>
                                )}
                              </>
                            </InputAdornment>
                          </>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <TextValidator
                      variant="outlined"
                      id="descricao"
                      name="descricao"
                      label="Descrição do álbum"
                      value={description}
                      onChange={(event) =>
                        // @ts-ignore
                        handleChangeDescription(event.target.value)
                      }
                      //validators={['required']}
                      //errorMessages={['Campo obrigatório!']}
                      multiline
                      lines="3"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <>
                            <InputAdornment position="end">
                              <>
                                {description.length > 280 ? (
                                  <Typography
                                    variant="body2"
                                    align="right"
                                    color="error"
                                  >
                                    {description.length}/280
                                  </Typography>
                                ) : (
                                  <Typography variant="body2" align="right">
                                    {description.length}/280
                                  </Typography>
                                )}
                              </>
                            </InputAdornment>
                          </>
                        )
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              {/* <Divider />
              <CardActions>
                <Button
                  onClick={() => history.goBack()}
                  color="secondary"
                  variant="outlined"
                >
                  Cancelar
                </Button>
                <Button type="submit" color="primary" variant="outlined">
                  {photoAlbumId ? 'Atualizar' : 'Salvar'}
                </Button>
              </CardActions> */}
            </ValidatorForm>
          </form>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className={classes.containerPhotos}>
          <CardHeader
            subheader={
              <>
                <Typography>Adicione as fotos para compor o álbum</Typography>
                {isMobile && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={enabledSortableContext}
                        onChange={toggleEnabledSortableContext}
                        color="primary"
                      />
                    }
                    label={
                      <div className={classes.helperContainer}>
                        <Typography>Habilitar ordenação</Typography>
                        <HelperToolTip>
                          Para organizar as fotos no álbum, basta segurá-las e
                          arrastá-las para a posição desejada utilizando o
                          recurso de arrastar e soltar (drag and drop).
                        </HelperToolTip>{' '}
                      </div>
                    }
                  />
                )}
              </>
            }
            title={'2 - Conteúdo do álbum'}
            action={
              !isMobile ? (
                <FormControlLabel
                  control={
                    <Switch
                      checked={enabledSortableContext}
                      onChange={toggleEnabledSortableContext}
                      color="primary"
                    />
                  }
                  label={
                    <div className={classes.helperContainer}>
                      <Typography>Habilitar ordenação</Typography>
                      <HelperToolTip>
                        Para organizar as fotos no álbum, basta segurá-las e
                        arrastá-las para a posição desejada utilizando o recurso
                        de arrastar e soltar (drag and drop).
                      </HelperToolTip>
                    </div>
                  }
                />
              ) : undefined
            }
          />
          <Divider />
          <Box padding={2} pb={0}>
            <Typography variant="body2" align="left">
              Total de fotos: <b>{foto.length}/300</b>
              {foto?.length > 0 && (
                <>
                  <Typography variant="body2" align="left">
                    Processadas:{' '}
                    <b>
                      {foto.filter((foto) => foto.uploaded).length}/
                      {foto.length}
                    </b>
                  </Typography>
                  {foto.filter((foto) => !foto.uploaded && foto.isError)
                    .length > 0 && (
                    <>
                      <Typography variant="body2" align="left">
                        Falhas:{' '}
                        <b>
                          {
                            foto.filter((foto) => !foto.uploaded && foto.isError)
                              .length
                          }
                          /{foto.length}
                        </b>
                      </Typography>
                      <Link
                        component="button"
                        variant="body2"
                        onClick={handleRetryAll}
                      >
                        Reprocessar todas as fotos com falhas
                      </Link>
                    </>
                  )}
                </>
              )}
            </Typography>
          </Box>
          <Box padding={2}>
            <PhotoList />
          </Box>
        </Card>
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent={isMobile ? 'space-between' : 'center'}
        style={{
          position: foto && foto.length > 30 ? 'sticky' : 'initial',
          marginTop: theme.spacing(2),
          bottom: theme.spacing(2)
        }}
      >
        <Grid xs={6} sm={'auto'} item>
          <Fab
            onClick={handleCancel}
            size={!isMobile ? 'medium' : 'large'}
            className={classes.fabButton}
            aria-label={'cancelar'}
            variant="extended"
            color={'default'}
          >
            {photoAlbumId ? 'Cancelar' : 'Voltar'}
          </Fab>
        </Grid>
        <Grid xs={6} sm={'auto'} item>
          <Fab
            onClick={handleSubmit}
            size={!isMobile ? 'medium' : 'large'}
            className={classes.fabButton}
            aria-label={'salvar alterações'}
            variant="extended"
            color={'primary'}
          >
            {photoAlbumId ? 'Atualizar' : 'Salvar álbum'}
          </Fab>
        </Grid>
      </Grid>
    </Page>
  )
}

export default PhotoAlbumEdit
