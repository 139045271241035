import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import ListRoundedIcon from '@material-ui/icons/ListRounded'
import {
  Tabs,
  Tab,
  Divider,
  colors,
  makeStyles,
  useTheme,
  useMediaQuery,
  LinearProgress
} from '@material-ui/core'
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone'
import { Page, Header } from 'components'
import { useDispatch } from 'react-redux'
import { albumAction } from '_actions'
import { MembershipPlanList } from './MembershipPlanList'
import MembershipPlanEdit from './MembershipPlanEdit'
import MemberList from './MemberList'
import MemberEdit from './MemberEdit'
import { AccessControl } from 'components/Utils'
import AccessDenied from 'views/Errors/AccessDenied'
import { useQueryClient } from 'react-query'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2, 2, 2)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}))

// @ts-ignore
const Members = (props) => {
  const { match, history } = props
  const { tab } = match.params
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const [refreshing, setRefreshing] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  // @ts-ignore
  const { memberId, membershipPlanId, eventoId } = useParams()

  // @ts-ignore
  const handleTabsChange = (event, value) => {
    history.push(`/members/${value}`)
  }

  const tabs = [
    { value: 'listaPlano', label: 'Lista Plano', icon: <ListRoundedIcon /> },
    {
      value: 'cadastroPlano',
      label: membershipPlanId ? 'Editar Plano' : 'Cadastro Plano',
      icon: membershipPlanId ? <EditTwoToneIcon /> : <AddCircleTwoToneIcon />,
      disabled: membershipPlanId ? true : false
    },
    { value: 'listaMembro', label: 'Lista Membro', icon: <ListRoundedIcon /> },
    {
      value: 'cadastroMembro',
      label: memberId ? 'Editar Membro' : 'Cadastro Membro',
      icon: memberId ? <EditTwoToneIcon /> : <AddCircleTwoToneIcon />,
      disabled: memberId ? true : false
    }
  ]

  const onRefresh = async () => {
    setRefreshing(true)
    try {
      await queryClient.refetchQueries({ active: true })
    } finally {
      setRefreshing(false)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(albumAction.deleteAllFoto())
    }
  }, [])

  if (!tab) {
    return <Redirect to="/members/listaPlano" />
  }

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/errors/error-404" />
  }

  return (
    <AccessControl
      rule={'membros'} //permissão necessária para acessar conteúdo
      // @ts-ignore
      yes={() => (
        <>
          {refreshing && (
            <LinearProgress
              style={{
                width: '100%',
                position: 'absolute'
              }}
            />
          )}
          {/* @ts-ignore */}
          <Page className={classes.root} title="Configurações">
            <Header
              subtitle="GERÊNCIA DE MEMBROS"
              title={'Membros'}
              isRefreshing={refreshing}
              onRefresh={onRefresh}
            />
            <Tabs
              className={classes.tabs}
              onChange={handleTabsChange}
              scrollButtons="auto"
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              variant={isMobile ? 'fullWidth' : 'standard'}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  icon={tab.icon}
                  // disabled={tab.disabled}
                />
              ))}
            </Tabs>
            <Divider className={classes.divider} />
            <div className={classes.content}>
              {tab === 'listaPlano' && <MembershipPlanList />}
              {tab === 'cadastroPlano' && <MembershipPlanEdit />}
              {tab === 'listaMembro' && <MemberList />}
              {tab === 'cadastroMembro' && <MemberEdit />}
            </div>
          </Page>
        </>
      )}
      // @ts-ignore
      no={() => <AccessDenied />}
    />
  )
}

export default Members
