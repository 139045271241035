import { useQuery } from 'react-query'

//? Own imports
import { SelectListItem } from 'types/api'
import { crudService } from '_services'

const getLotesByEventAndTicketTypeSelect = async (
  eventId: number,
  tipoIngressoId: number
) => {
  const apiEndpoint = `lotes/select/evento/${eventId}/tipoIngresso/${tipoIngressoId}`
  try {
    const { data } = await crudService.get(apiEndpoint)
    return data
  } catch (e) {
    return e
  }
}

export default function useLotesByEventAndTicketTypeSelect(
  eventId: number,
  tipoIngressoId: number
) {
  return useQuery<SelectListItem[]>(
    ['lotesByEventAndTicketTypeSelect', eventId, tipoIngressoId],
    () => getLotesByEventAndTicketTypeSelect(eventId, tipoIngressoId),
    {
      enabled: eventId > 0 && tipoIngressoId > 0,
      cacheTime: Infinity,
      staleTime: 600000 // 10 minutes
    }
  )
}
