import React, { useEffect, useState } from 'react'
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  Switch,
  TextField,
  InputAdornment,
  IconButton,
  Divider,
  useTheme,
  useMediaQuery,
  Chip,
  CircularProgress,
  Tooltip,
  Fade,
  Box
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Autocomplete } from '@material-ui/lab'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import CloseIcon from '@material-ui/icons/Close'

//? Own imports
import { useDidMountEffect } from 'utils/useDidMountEffect'
import {
  useEvents,
  useLotesByEventSelect,
  useStatusIngressoSelect,
  useTipoIngressoSelect
} from 'hooks-querys'
import { history, toLocalDateFormat, useSearchQuery } from 'utils'
import { Evento } from 'types/api'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    width: '100%'
  },
  divider: {
    height: 28,
    margin: 4
  }
}))

const SelectFilters = () => {
  const classes = useStyles()
  const theme = useTheme()
  const querySearch = useSearchQuery()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [selectedEvento, setSelectedEvento] = useState<Evento | null>(null)
  const [eventoInputValue, setEventoInputValue] = useState('')
  const [showPastEvents, setShowPastEvents] = useState(false)

  const estabelecimentoId = useSelector(
    // @ts-ignore: //ainda não foi tipado o redux state
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const textSearch = querySearch.get('busca') || ''
  const [searchText, setSearchText] = useState(textSearch || '')
  const eventId = querySearch.get('eventId') || '0'
  const loteId = querySearch.get('loteId') || ''
  const tipoIngressoId = querySearch.get('tipoIngressoId')
  const statusId = querySearch.get('statusId') || ''
  const validados = querySearch.get('validados') || ''
  const {
    data: events,
    isLoading: isLoadingEvents,
    isFetching: isFetchingEvents
  } = useEvents(estabelecimentoId, showPastEvents, isMobile)
  const {
    data: statusIngressoSelect,
    isLoading: isLoadingStatusIngressoSelect
  } = useStatusIngressoSelect()

  const {
    isLoading: isLoadingLotesByEventSelect,
    data: lotesByEventSelect
  } = useLotesByEventSelect(Number(eventId) || 0, Number(tipoIngressoId) || 0)
  const {
    isLoading: isLoadingTipoIngressoSelect,
    data: tipoIngressoSelect
  } = useTipoIngressoSelect(estabelecimentoId || 0)

  useEffect(() => {
    setSearchText(textSearch)
  }, [textSearch])

  useEffect(() => {
    //? função executada para buscar o evento o qual esta na url (?eventId=)
    //? se não existir evento na url não faz nada

    if (eventId && Number(eventId) > 0) {
      const event =
        events &&
        events.length > 0 &&
        events.find((e) => e.id === Number(eventId))
      //? se encontrar o evento na url, seta o evento selecionado,
      //? se não encontrar, troca a o estado showPastEvents para true,
      //? pois o evento informado pode ser um evento passado, e não existir na lista de eventos;
      //? com isso, a lista de evento efetua um novo fetch para buscar os eventos passados,
      //? e gera um novo efeito colateral caindo nesse useEffect novamente

      if (event) {
        setSelectedEvento(event)
      } else if (selectedEvento === null) {
        setShowPastEvents(!showPastEvents)
      }
    }
  }, [events])

  const handleSubmitSearch = (event: React.FormEvent) => {
    event.preventDefault()

    const sanitizeInput = (input: string) => input.replace(/[^\w\s@]/g, '')

    const sanitizedSearchText = sanitizeInput(searchText.trim())

    if (sanitizedSearchText) {
      querySearch.set('busca', sanitizedSearchText)
      history.push({ search: querySearch.toString() })
    }
  }

  const handleRemoveFilter = (property: string) => {
    querySearch.delete(property)
    history.push({ search: querySearch.toString() })
  }

  const handleChangeSelectFilter = (property: string, value: string) => {
    if (!value) {
      handleRemoveFilter(property)
    } else {
      querySearch.set(property, value)
      history.push({ search: querySearch.toString() })
    }
  }
  const clearTextSearch = () => {
    querySearch.delete('busca')
    history.push({ search: querySearch.toString() })
  }
  const handleChangeValidatedTickets = (showValidatedTickets: string) => {
    querySearch.set('validados', showValidatedTickets)
    querySearch.delete('statusId')
    history.push({ search: querySearch.toString() })
  }

  useDidMountEffect(() => {
    history.push('/ingressos')
  }, [estabelecimentoId])

  useDidMountEffect(() => {
    if (selectedEvento?.id) {
      querySearch.set('eventId', selectedEvento?.id?.toString())
      history.push({ search: querySearch.toString() })
    }
  }, [statusId, selectedEvento])

  useEffect(() => {
    if (selectedEvento && selectedEvento.id) {
      if (eventoInputValue === '') {
        setEventoInputValue(
          selectedEvento.nome &&
            `${selectedEvento.nome} ${
              selectedEvento.dataHoraInicio
                ? ` - (${toLocalDateFormat(selectedEvento.dataHoraInicio)})`
                : ''
            }`
        )
      }
    }
  }, [selectedEvento])

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <Autocomplete
              loading={isLoadingEvents}
              id="combo-box-evento"
              options={events || []}
              value={selectedEvento}
              onChange={(event, newValue, reason) => {
                setSelectedEvento(newValue)
                if (reason === 'clear') {
                  //? se o usuário clicar no botão limpar
                  setEventoInputValue('')
                  history.push('/ingressos')
                }
              }}
              getOptionLabel={(evento) =>
                evento.nome &&
                `${evento.nome} ${
                  evento.dataHoraInicio
                    ? ` - (${toLocalDateFormat(evento.dataHoraInicio)})`
                    : ''
                }`
              }
              placeholder="Selecione o evento..."
              onInputChange={(event, newInputValue) => {
                newInputValue !== null
                  ? setEventoInputValue(newInputValue)
                  : setEventoInputValue('')
              }}
              inputValue={eventoInputValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecione o evento"
                  placeholder="Selecione o evento..."
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {!isMobile && (
                          <InputAdornment position="end">
                            <>
                              {isFetchingEvents && (
                                <CircularProgress
                                  style={{ marginRight: 10 }}
                                  size={20}
                                  color="primary"
                                />
                              )}
                              <Switch
                                color="primary"
                                onChange={(event) => {
                                  setShowPastEvents(event.target.checked)
                                }}
                                checked={showPastEvents}
                                size="small"
                                inputProps={{
                                  'aria-label': 'Listar eventos encerrados'
                                }}
                              />
                              <Chip
                                variant="default"
                                size="small"
                                label="Listar eventos encerrados"
                                color={showPastEvents ? 'primary' : 'default'}
                                onClick={() => {
                                  setShowPastEvents(!showPastEvents)
                                }}
                              />
                            </>
                          </InputAdornment>
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Fade in={!!(selectedEvento && selectedEvento.id > 0)}>
          <Grid item xs={12} sm={6}>
            <form autoComplete="off" onSubmit={handleSubmitSearch}>
              <TextField
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {textSearch.length >= 1 && (
                        <>
                          <Tooltip title="Limpar">
                            <IconButton onClick={clearTextSearch}>
                              <CloseIcon opacity="0.8" />
                            </IconButton>
                          </Tooltip>
                          <Divider
                            className={classes.divider}
                            orientation="vertical"
                          />
                        </>
                      )}
                      <Tooltip title="Buscar">
                        <IconButton type="submit" aria-label="buscar">
                          <SearchRoundedIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
                fullWidth
                required
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
                variant="outlined"
                id="textForSearch"
                label="Buscar"
                placeholder="Busque por nome, CPF ou N.º..."
              />
            </form>
          </Grid>
        </Fade>
        <Fade in={!!(selectedEvento && selectedEvento.id > 0)}>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel id="status-ingresso-label">
                Filtrar por status
              </InputLabel>
              <Select
                disabled={isLoadingStatusIngressoSelect}
                placeholder="Filtrar por status"
                labelId="status-ingresso-label"
                id="status-ingresso-select"
                value={statusId.toString()}
                onChange={(event) => {
                  handleChangeSelectFilter(
                    'statusId',
                    event.target.value as string
                  )
                }}
                label="Filtrar por status"
                endAdornment={
                  <>
                    {!isMobile && (
                      <InputAdornment position="end">
                        <>
                          <Switch
                            color="primary"
                            onChange={(event) => {
                              handleChangeValidatedTickets(
                                event.target.checked.toString()
                              )
                            }}
                            checked={validados === 'true'}
                            size="small"
                            inputProps={{
                              'aria-label': 'Listar ingressos validados'
                            }}
                          />
                          <Chip
                            variant="default"
                            size="small"
                            label="Listar ingressos validados"
                            color={validados === 'true' ? 'primary' : 'default'}
                            onClick={() => {
                              handleChangeValidatedTickets(
                                validados !== 'true' ? 'true' : 'false'
                              )
                            }}
                          />
                        </>
                      </InputAdornment>
                    )}
                  </>
                }
              >
                <MenuItem disabled={statusId === '0'} key={'0'} value={'0'}>
                  {statusId === '0' ? (
                    'Filtrar por status'
                  ) : (
                    <strong>Limpar filtro</strong>
                  )}
                </MenuItem>
                {statusIngressoSelect &&
                  statusIngressoSelect.length > 0 &&
                  statusIngressoSelect.map((statusIngresso) => (
                    <MenuItem
                      disabled={
                        statusIngresso.value == statusId.toString()
                          ? true
                          : false
                      }
                      key={statusIngresso.value}
                      value={statusIngresso.value}
                    >
                      {statusIngresso.text}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Fade>
        <Fade in={!!(selectedEvento && selectedEvento.id > 0)}>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel id="tipo-ingresso-select-label">
                Filtrar por tipo de ingresso
              </InputLabel>
              <Select
                disabled={isLoadingTipoIngressoSelect}
                placeholder={
                  isLoadingTipoIngressoSelect
                    ? 'Carregando...'
                    : 'Filtrar por tipo de ingresso'
                }
                labelId="tipo-ingresso-select-label"
                id="tipo-ingresso-select"
                value={tipoIngressoId}
                onChange={(event) => {
                  handleChangeSelectFilter(
                    'tipoIngressoId',
                    event.target.value as string
                  )
                  handleRemoveFilter('loteId')
                }}
                label={
                  isLoadingTipoIngressoSelect
                    ? 'Carregando...'
                    : 'Filtrar por tipo de ingresso'
                }
              >
                <MenuItem
                  disabled={!tipoIngressoId}
                  key={'0'}
                  value={undefined}
                >
                  {!tipoIngressoId ? (
                    'Filtrar por tipo de ingresso'
                  ) : (
                    <strong>Limpar filtro</strong>
                  )}
                </MenuItem>
                {Array.isArray(tipoIngressoSelect) &&
                  tipoIngressoSelect?.map((selectItem) => (
                    <MenuItem
                      disabled={tipoIngressoId === selectItem?.value}
                      key={selectItem?.value}
                      value={selectItem?.value}
                    >
                      {selectItem?.text}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Fade>
        {Number(tipoIngressoId) > 0 && (
          <Fade
            in={
              !!(
                Number(tipoIngressoId) > 0 &&
                selectedEvento &&
                selectedEvento.id > 0
              )
            }
          >
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="lote-select-label">
                  {isLoadingLotesByEventSelect
                    ? 'Carregando...'
                    : 'Filtrar por lote'}
                </InputLabel>
                <Select
                  disabled={isLoadingLotesByEventSelect}
                  endAdornment={
                    isLoadingLotesByEventSelect && (
                      <InputAdornment position="end">
                        <CircularProgress
                          style={{ marginRight: 25 }}
                          size={20}
                          color="primary"
                        />
                      </InputAdornment>
                    )
                  }
                  placeholder={
                    isLoadingLotesByEventSelect
                      ? 'Carregando...'
                      : 'Filtrar por lote'
                  }
                  labelId="lote-select-label"
                  id="lote-select-select"
                  value={loteId}
                  onChange={(event) => {
                    handleChangeSelectFilter(
                      'loteId',
                      event.target.value as string
                    )
                  }}
                  label={
                    isLoadingLotesByEventSelect
                      ? 'Carregando...'
                      : 'Filtrar por lote'
                  }
                >
                  <MenuItem disabled={!loteId} key={'0'} value={undefined}>
                    {!loteId ? (
                      'Filtrar por lote'
                    ) : (
                      <strong>Limpar filtro</strong>
                    )}
                  </MenuItem>
                  {Array.isArray(lotesByEventSelect) &&
                    lotesByEventSelect?.map((selectItem) => (
                      <MenuItem
                        disabled={loteId === selectItem?.value}
                        key={selectItem?.value}
                        value={selectItem?.value}
                      >
                        {selectItem?.text}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Fade>
        )}

        {isMobile && (
          <Fade in={!!(selectedEvento && selectedEvento.id > 0)}>
            <Grid item xs={12} sm={6}>
              <Switch
                color="primary"
                onChange={(event) => {
                  handleChangeValidatedTickets(event.target.checked.toString())
                }}
                checked={validados === 'true'}
                size="small"
                inputProps={{
                  'aria-label': 'Listar ingressos validados'
                }}
              />
              <Chip
                variant="default"
                size="small"
                label="Listar ingressos validados"
                color={validados === 'true' ? 'primary' : 'default'}
                onClick={() => {
                  handleChangeValidatedTickets(
                    validados !== 'true' ? 'true' : 'false'
                  )
                }}
              />
            </Grid>
          </Fade>
        )}
      </Grid>
    </div>
  )
}

export default SelectFilters
