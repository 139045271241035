import { useQuery } from 'react-query'

//? Own imports
import { Ingresso } from 'types/api'
import { crudService } from '_services'

type TicketsByEventReturn = {
  items: Ingresso[]
  totalRows: number
}
type TicketsByEventParams = {
  eventoId: number
  statusIngressoId?: number
  loteId?: number
  tipoIngressoId?: number
  page: number
  limit: number
  term?: string
  order?: string
  validados?: string
  direction?: 'asc' | 'desc'
}
const getTickets = async (params: TicketsByEventParams) => {
  try {
    const {
      limit,
      page,
      direction,
      order,
      term,
      eventoId,
      loteId,
      statusIngressoId,
      tipoIngressoId,
      validados
    } = params

    const queryParams = new URLSearchParams()

    if (eventoId) queryParams.append('EventoId', String(eventoId))
    if (page) queryParams.append('Page', String(page + 1))
    if (limit) queryParams.append('Limit', String(limit))
    if (term) queryParams.append('Term', term)
    if (validados) queryParams.append('Validados', validados)
    if (order) queryParams.append('Order', order)
    if (direction) queryParams.append('Direction', direction)
    if (loteId) queryParams.append('LoteId', String(loteId))
    if (statusIngressoId)
      queryParams.append('StatusIngressoId', String(statusIngressoId))
    if (tipoIngressoId)
      queryParams.append('TipoIngressoId', String(tipoIngressoId))

    const queryString = queryParams.toString()

    const { data } = await crudService.get(
      `ingressos/evento/search/v2/?${queryString}`
    )
    if (data) {
      return data
    } else {
      throw new Error('Invalid response')
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export default function useTickets(params: TicketsByEventParams) {
  return useQuery<TicketsByEventReturn>(
    ['tickets', params],
    () => getTickets(params),
    {
      enabled: !!(Number(params?.eventoId) > 0),
      staleTime: 600000, // 10 minutes,
      keepPreviousData: true
    }
  )
}
