import { useQuery } from 'react-query'

//? Own Imports
import { crudService } from '_services'
import { MemberListParams, MemberListReturn, UseQueryOptions } from './member'

const getMemberList = async (
  params: MemberListParams
): Promise<MemberListReturn> => {
  const {
    limit,
    estabelecimentoId,
    page,
    direction,
    order,
    term,
    statusMembroPlanoId,
    planoId
  } = params

  try {
    const queryParams = new URLSearchParams()

    if (estabelecimentoId)
      queryParams.append('EstabelecimentoId', String(estabelecimentoId))
    if (page) queryParams.append('Page', String(page + 1))
    if (limit) queryParams.append('Limit', String(limit))
    if (term) queryParams.append('Term', term)
    if (order) queryParams.append('Order', order)
    if (direction) queryParams.append('Direction', direction)
    if (statusMembroPlanoId)
      queryParams.append('StatusMembroPlanoId', String(statusMembroPlanoId))
    if (planoId) queryParams.append('PlanoId', String(planoId))

    const queryString = queryParams.toString()

    const { data } = await crudService.get(`membros/admin/list?${queryString}`)

    if (data) {
      return data
    } else {
      throw new Error('Invalid response')
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export default function useMemberList(
  params: MemberListParams,
  options?: UseQueryOptions<MemberListReturn>
) {
  return useQuery<MemberListReturn>(
    ['memberList', params],
    () => getMemberList(params),
    {
      enabled: params?.estabelecimentoId > 0,
      staleTime: 600000, // 10 minutes,
      keepPreviousData: true,
      onError: () => {
        // @ts-ignore
        toast.error('Erro ao buscar membros', {
          toastId: 'memberList'
        })
      },
      ...options?.config
    }
  )
}
