import { useQuery } from 'react-query'

//? Own imports
import { SelectListItem } from 'types/api'
import { crudService } from '_services'

const getTipoIngressoSelect = async (establishmentId: number) => {
  const apiEndpoint = `tiposIngressos/estabelecimento/${establishmentId}/select`
  try {
    const { data } = await crudService.get(apiEndpoint)
    return data
  } catch (e) {
    return e
  }
}

export default function useTipoIngressoSelect(establishmentId: number) {
  return useQuery<SelectListItem[]>(
    ['tipoIngressoSelect', establishmentId],
    () => getTipoIngressoSelect(establishmentId),
    {
      enabled: establishmentId > 0,
      cacheTime: Infinity,
      staleTime: 600000 // 10 minutes
    }
  )
}
