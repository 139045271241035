import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
  Divider
} from '@material-ui/core'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import CloseIcon from '@material-ui/icons/Close'

//? Own imports
import { history, useSearchQuery } from 'utils'
import {
  useEstablishmentPlansSelect,
  useStatusMembroPlanoSelect
} from 'hooks-querys'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  divider: {
    height: 28,
    margin: 4
  }
}))

const Filters = () => {
  const classes = useStyles()
  const estabelecimentoId = useSelector(
    // @ts-ignore: //ainda não foi tipado o redux state
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const {
    data: statusMembroPlanoList,
    isLoading: isLoadingStatusMembroPlano
  } = useStatusMembroPlanoSelect()

  const {
    data: establishmentPlansSelectList,
    isLoading: isLoadingEstablishmentPlansSelect
  } = useEstablishmentPlansSelect(estabelecimentoId)

  const querySearch = useSearchQuery()
  const selectStatusMembroPlano = querySearch.get('statusId')
  const selectEstablishmentPlanId = querySearch.get('planoId')
  const textSearch = querySearch.get('busca') || ''
  const [searchText, setSearchText] = useState(textSearch || '')
  useEffect(() => {
    setSearchText(textSearch)
  }, [textSearch])

  const handleRemoveFilterSelectStatus = () => {
    querySearch.delete('statusId')
  }

  const handleChangeSelectStatus = (selectStatusMembroPlano: string) => {
    if (!selectStatusMembroPlano) {
      handleRemoveFilterSelectStatus()
    } else {
      querySearch.set('statusId', selectStatusMembroPlano)
    }
    history.push({ search: querySearch.toString() })
  }
  const handleRemoveFilterSelectEstablishmentPlan = () => {
    querySearch.delete('planoId')
  }

  const handleChangeSelectEstablishmentPlan = (
    selectEstablishmentPlan: string
  ) => {
    if (!selectEstablishmentPlan) {
      handleRemoveFilterSelectEstablishmentPlan()
    } else {
      querySearch.set('planoId', selectEstablishmentPlan)
    }
    history.push({ search: querySearch.toString() })
  }

  const clearTextSearch = () => {
    querySearch.delete('busca')
    history.push({ search: querySearch.toString() })
  }
  const handleSubmitSearch = (event: React.FormEvent) => {
    event.preventDefault()

    // Função para remover caracteres indesejados
    const sanitizeInput = (input: string) => input.replace(/[^\w\s@]/g, '')

    const sanitizedSearchText = sanitizeInput(searchText.trim())

    if (sanitizedSearchText) {
      querySearch.set('busca', sanitizedSearchText)
      history.push({ search: querySearch.toString() })
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel id="status-membro-plano-label">
              Filtrar por status
            </InputLabel>
            <Select
              disabled={isLoadingStatusMembroPlano}
              placeholder={
                isLoadingStatusMembroPlano
                  ? 'Carregando...'
                  : 'Filtrar por status'
              }
              labelId="status-membro-plano-label"
              id="status-membro-plano-select"
              value={selectStatusMembroPlano}
              onChange={(event) => {
                handleChangeSelectStatus(event.target.value as string)
              }}
              label={
                isLoadingStatusMembroPlano
                  ? 'Carregando...'
                  : 'Filtrar por status'
              }
            >
              <MenuItem
                disabled={!selectStatusMembroPlano}
                key={'0'}
                value={undefined}
              >
                {!selectStatusMembroPlano ? (
                  'Filtrar por status'
                ) : (
                  <strong>Limpar filtro</strong>
                )}
              </MenuItem>
              {Array.isArray(statusMembroPlanoList) &&
                statusMembroPlanoList?.map((selectItem) => (
                  <MenuItem
                    disabled={selectStatusMembroPlano === selectItem?.value}
                    key={selectItem?.value}
                    value={selectItem?.value}
                  >
                    {selectItem?.text}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel id="plano-label">Filtrar por plano</InputLabel>
            <Select
              disabled={isLoadingEstablishmentPlansSelect}
              placeholder={
                isLoadingEstablishmentPlansSelect
                  ? 'Carregando...'
                  : 'Filtrar por plano'
              }
              labelId="plano-label"
              id="plano-select"
              value={selectEstablishmentPlanId}
              onChange={(event) => {
                handleChangeSelectEstablishmentPlan(
                  event.target.value as string
                )
              }}
              label={
                isLoadingEstablishmentPlansSelect
                  ? 'Carregando...'
                  : 'Filtrar por plano'
              }
            >
              <MenuItem
                disabled={!selectEstablishmentPlanId}
                key={'0'}
                value={undefined}
              >
                {!selectEstablishmentPlanId ? (
                  'Filtrar por plano'
                ) : (
                  <strong>Limpar filtro</strong>
                )}
              </MenuItem>
              {establishmentPlansSelectList?.map((establishmentPlanItem) => (
                <MenuItem
                  disabled={
                    selectEstablishmentPlanId === establishmentPlanItem?.value
                  }
                  key={establishmentPlanItem?.value}
                  value={establishmentPlanItem?.value}
                >
                  {establishmentPlanItem?.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <form autoComplete="off" onSubmit={handleSubmitSearch}>
            <TextField
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {textSearch?.length >= 1 && (
                      <>
                        <Tooltip title="Limpar">
                          <IconButton onClick={clearTextSearch}>
                            <CloseIcon opacity="0.8" />
                          </IconButton>
                        </Tooltip>
                        <Divider
                          className={classes.divider}
                          orientation="vertical"
                        />
                      </>
                    )}
                    <Tooltip title="Buscar">
                      <IconButton type="submit" aria-label="buscar">
                        <SearchRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                )
              }}
              fullWidth
              variant="outlined"
              id="textForSearch"
              label="Buscar"
              placeholder="Busque por nome ou CPF/CNPJ..."
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
            />
          </form>
        </Grid>
      </Grid>
    </div>
  )
}

export default Filters
