import React from 'react'
import Table from '@material-ui/core/Table'
import Tooltip from '@material-ui/core/Tooltip'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Grid, useTheme } from '@material-ui/core'
import EventAvailableTwoToneIcon from '@material-ui/icons/EventAvailableTwoTone'
import EventBusyTwoToneIcon from '@material-ui/icons/EventBusyTwoTone'
import { connect, useDispatch, useSelector } from 'react-redux'
import { loteAction, eventoAction } from '_actions'
import { useParams, withRouter } from 'react-router-dom'
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  ControlPointDuplicate as ControlPointDuplicateIcon
} from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import { useConfirm } from 'material-ui-confirm'
import { toLocalDateFormat } from 'utils'
import constants from 'theme/constants'
import { AccessControl } from 'components/Utils'
import { useQueryClient } from 'react-query'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2, 0, 4, 0)
  },
  paper: {
    ...constants.shadowCard
  }
})

const LoteList = (props) => {
  const queryClient = useQueryClient()
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { estabelecimentoId } = useSelector((state) => state.evento)
  const { classes } = props
  let { id } = useParams()
  const theme = useTheme()
  const convertGenero = (genero) => {
    switch (genero) {
      case 'M':
        return 'Masculino'
      case 'F':
        return 'Feminino'
      case 'U':
        return 'Unissex'
      default:
        return ''
    }
  }

  const findDescricaoTipoIngresso = (id) => {
    if (props.tipoIngresso && props.tipoIngresso.length > 0) {
      var item = props.tipoIngresso.find((item) => item.id === id)
      return item && item.descricao ? item.descricao : ''
    } else {
      return null
    }
  }

  const handleClickEdit = (lote) => {
    dispatch(loteAction.editLotesDetails(lote))
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const handleClickDuplicate = (lote) => {
    dispatch(loteAction.editLotesDetails({ ...lote, id: 0 }))
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const handleClickDelete = (loteId) => {
    confirm({
      title: 'Você deseja excluir este Lote?',
      description:
        'Confirmando essa operação, este lote não aparecerá mais no site da Corujas.',
      confirmationText: 'Sim, excluir!',
      cancellationText: 'Nãããoo!'
    }).then(() => {
      if (id) {
        dispatch(loteAction.deleteLoteById(loteId, id))
      } else {
        dispatch(eventoAction.deleteLoteLocaleById(loteId))
      }
    })
  }

  // Retornar nome do Plano
  const getPlanNameById = (planId) => {
    const plans = queryClient.getQueryData([
      'establishmentPlansSelect',
      estabelecimentoId
    ])

    if (!plans || !Array.isArray(plans)) {
      console.error('Plans data is not available or invalid:', plans)
      return ''
    }

    const plan = plans.find((plan) => plan?.id === planId)

    return plan?.text || ''
  }

  return (
    <main className={classes.root}>
      <Grid container>
        <Grid item xs={6}>
          <Typography align="left" gutterBottom variant="h7">
            <b>{'Lista de Lotes'}</b>
          </Typography>
        </Grid>
        {props.lote.length !== 0 ? (
          <Grid item xs={6}>
            <Typography align="right" gutterBottom>
              Total de Lotes encontrados: <b>{props.lote.length}</b>
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      {props.lote.length !== 0 ? (
        <TableContainer component={Paper} className={classes.paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  Descrição - Tipo&nbsp;Ingresso
                </TableCell>
                <TableCell align="center">Sexo</TableCell>
                <TableCell align="center">Valor (R$)</TableCell>
                <TableCell align="center">Repassar Taxa</TableCell>
                <TableCell align="center">Quantidade</TableCell>
                <TableCell align="center">Limite Quantidade</TableCell>
                <TableCell align="center">Data Início</TableCell>
                <TableCell align="center">Data Término</TableCell>
                <TableCell align="center">Disponível</TableCell>
                <AccessControl
                  rule={'membros'}
                  // @ts-ignore
                  yes={() => <TableCell align="center">Check-In</TableCell>}
                />
                {props.actions ? (
                  <TableCell align="right">Ações</TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.lote.map((row) => {
                if (row.excluido === true) {
                  return null
                } else {
                  return (
                    <TableRow hover key={row.id}>
                      <TableCell align="left" component="th" scope="row">
                        {row.descricao}
                        {' - '}
                        {id
                          ? row.tipoIngresso.descricao
                          : findDescricaoTipoIngresso(row.tipoIngressoId)}
                      </TableCell>
                      <TableCell align="center">
                        {row.genero ? (
                          <Tooltip title={convertGenero(row.genero)}>
                            <Typography>{row.genero.toUpperCase()}</Typography>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Não definido">
                            <Typography>{'-'}</Typography>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.valor === 0
                          ? 'Gratuito'
                          : Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(row.valor)}
                      </TableCell>
                      <TableCell size="small" align="center">
                        {row.valor === 0 ? (
                          'Não possui taxa'
                        ) : row.repassarTaxa === true ? (
                          <Tooltip title="Taxa repassada para o cliente">
                            <CheckRoundedIcon
                              htmlColor={theme.palette.success.dark}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Taxa não repassada para o cliente">
                            <CloseRoundedIcon color="error" />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="center">{row.quantidade}</TableCell>
                      <TableCell align="center">
                        {!row.limiteQuantidadePedido
                          ? '-'
                          : row.limiteQuantidadePedido}
                      </TableCell>
                      <TableCell align="center">
                        {toLocalDateFormat(row.dataHoraInicio)}
                      </TableCell>
                      <TableCell align="center">
                        {toLocalDateFormat(row.dataHoraTermino)}
                      </TableCell>

                      <TableCell align="center" component="th" scope="row">
                        {row.disponivel ? (
                          <EventAvailableTwoToneIcon color="primary" />
                        ) : (
                          <EventBusyTwoToneIcon color="error" />
                        )}
                      </TableCell>

                      <AccessControl
                        rule={'membros'}
                        // @ts-ignore
                        yes={() => (
                          <TableCell align="center">
                            {row.checkIn ? (
                              <Tooltip arrow title={getPlanNameById()}>
                                <CheckRoundedIcon
                                  htmlColor={theme.palette.success.dark}
                                />
                              </Tooltip>
                            ) : (
                              '-'
                            )}
                          </TableCell>
                        )}
                      />

                      {props.actions ? (
                        <TableCell align="right">
                          <Tooltip arrow title="Duplicar Lote">
                            <IconButton
                              size="small"
                              className={classes.button}
                              aria-label="Duplicate"
                              component="a"
                              onClick={() => handleClickDuplicate(row)}
                            >
                              <ControlPointDuplicateIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title="Editar Lote">
                            <IconButton
                              size="small"
                              className={classes.button}
                              aria-label="Edit"
                              component="a"
                              onClick={() => handleClickEdit(row)}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title="Clique para excluir!">
                            <IconButton
                              size="small"
                              className={classes.button}
                              aria-label="Delete"
                              onClick={() => handleClickDelete(row.id)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  )
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography gutterBottom variant="h7">
          <b>{'Nenhum Lote encontrado!'}</b>
        </Typography>
      )}
    </main>
  )
}
LoteList.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    lote: state.evento.lote,
    tipoIngresso: state.tipoIngresso.tipoIngresso
  }
}

const connectedLoteListPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(LoteList))
)
export { connectedLoteListPage as LoteList }
