import React from 'react'
import {
  TableBody,
  Table,
  makeStyles,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Toolbar,
  Tooltip,
  IconButton,
  Backdrop,
  CircularProgress,
  Box,
  TableSortLabel,
  TablePagination,
  Avatar
} from '@material-ui/core'
import ReactToPrint from 'react-to-print'
import { CSVLink } from 'react-csv'
import PrintRoundedIcon from '@material-ui/icons/PrintRounded'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'
import { toast } from 'react-toastify'

//? Own imports
import {
  cpfCnpjMask,
  formatCurrency,
  getImageUrls,
  getInitials,
  getStatusIngressoColor,
  history,
  phoneMask,
  toLocalDateFormat,
  useSearchQuery
} from 'utils'
import { Label } from 'components'
import { Ingresso } from 'types/api'
import constants from 'theme/constants'
import { useTickets } from 'hooks-querys'

const headers = [
  { label: 'N.º', key: 'id' },
  { label: 'Cliente', key: 'usuario.nome' },
  { label: 'CPF/CNPJ', key: 'usuario.cpfCnpj' },
  { label: 'Celular', key: 'usuario.telefone' },
  { label: 'Email', key: 'usuario.email' },
  { label: 'Tipo de Ingresso', key: 'tipoIngresso' },
  { label: 'Valor Pago (R$)', key: 'valorPago' },
  { label: 'Data Hora Validação', key: 'dataHoraValidacao' },
  { label: 'Status', key: 'statusIngresso' }
]

export const prepareDataForCSV = (tickets: Ingresso[]) => {
  return tickets.map((ticket) => ({
    id: ticket.id,
    'usuario.nome': ticket.usuario.nome,
    'usuario.cpfCnpj': cpfCnpjMask(ticket.usuario.cpfCnpj),
    'usuario.telefone': phoneMask(ticket.usuario.telefone),
    'usuario.email': ticket.usuario.email,
    tipoIngresso: `${ticket.lote.tipoIngresso.descricao} - (${ticket.lote.descricao})`,
    valorPago: (ticket.valor - ticket.valorDesconto)?.toFixed(2),
    dataHoraValidacao: toLocalDateFormat(ticket?.dataHoraValidacao),
    statusIngresso: ticket.statusIngresso.descricao
  }))
}

const handleDownload = () => {
  toast.success('Download concluído com sucesso! 👍', {
    position: 'top-right'
  })
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700
  },
  rootToolbarStyles: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  titleToolbarStyles: {
    flex: '1 1 100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    ...constants.shadowCard
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(2)
  },
  statusCell: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  }
}))

type Order = 'asc' | 'desc'
interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  order: Order
  orderBy: string
}
interface HeadCell {
  id: string
  label: string
  align?: 'right' | 'left'
  enableSort: boolean
}

const headCells: HeadCell[] = [
  {
    id: 'id',
    label: 'N.º',
    align: 'left',
    enableSort: true
  },
  { id: 'nome', label: 'Cliente', enableSort: true },
  { id: 'cpfCnpj', label: 'CPF/CNPJ', enableSort: false },
  {
    id: 'telefone',
    label: 'Telefone',
    enableSort: false
  },
  {
    id: 'tipoIngresso',
    label: 'Tipo Ingresso',
    enableSort: true
  },
  {
    id: 'valor',
    label: 'Valor Pago (R$)',
    enableSort: false
  },
  { id: 'status', label: 'Status', enableSort: true, align: 'right' }
]

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props
  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property)
  }
  return (
    <TableHead>
      <TableRow>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'center'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              disabled={!headCell?.enableSort}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id as string)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const TableIngressos = () => {
  const classes = useStyles()
  const refTable = React.useRef(null)
  const querySearch = useSearchQuery()
  const order = (querySearch.get('direction') || 'asc') as 'asc' | 'desc'
  const orderBy = querySearch.get('order') || 'nome'

  const { data, isLoading, isFetching } = useTickets({
    eventoId: Number(querySearch.get('eventId') || 0),
    term: querySearch.get('busca') || '',
    statusIngressoId: Number(querySearch.get('statusId')) || 0,
    limit: Number(querySearch.get('limit') || 15),
    page: Number(querySearch.get('page') || 0),
    loteId: Number(querySearch.get('loteId')) || 0,
    tipoIngressoId: Number(querySearch.get('tipoIngressoId')) || 0,
    validados: querySearch.get('validados') || '',
    order: orderBy,
    direction: order
  })

  const csvData = prepareDataForCSV(data?.items || [])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    querySearch.set('direction', isAsc ? 'desc' : 'asc')
    querySearch.set('order', property)
    history.push({ search: querySearch.toString() })
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    querySearch.set('page', newPage?.toString())
    history.push({ search: querySearch.toString() })
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    querySearch.set('limit', event.target.value)
    querySearch.set('page', '0')
    history.push({ search: querySearch.toString() })
  }

  if (isLoading) {
    return (
      <Box marginY={4}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box mb={2}>
            <Typography variant="h6">Carregando dados...</Typography>
          </Box>
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box marginTop={4}>
      <Backdrop className={classes.backdrop} open={isFetching}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Typography variant="body2" align="left" gutterBottom>
        {data && data?.totalRows > 0 ? (
          <>
            Total de dados encontrados: <strong>{data?.totalRows}</strong>
          </>
        ) : (
          <strong>Nenhum dado encontrado.</strong>
        )}
      </Typography>
      {data && data?.totalRows > 0 && (
        <Paper ref={refTable} className={classes.paper}>
          <Toolbar className={classes.rootToolbarStyles}>
            <Typography
              className={classes.titleToolbarStyles}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Lista de Ingressos
            </Typography>
            <CSVLink
              data={csvData}
              headers={headers}
              target="_blank"
              filename={'lista-de-ingressos.csv'}
              onClick={() => {
                handleDownload()
              }}
            >
              <Tooltip title="Exportar em CSV">
                <IconButton aria-label="exportar em csv">
                  <GetAppRoundedIcon />
                </IconButton>
              </Tooltip>
            </CSVLink>
            <ReactToPrint
              trigger={() => (
                <Tooltip title="Imprimir">
                  <IconButton aria-label="gerar relatório">
                    <PrintRoundedIcon />
                  </IconButton>
                </Tooltip>
              )}
              content={() => refTable.current}
            />
          </Toolbar>
          {/* usado para 'forçar' a orientação da pagina para 'paisagem' afim de imprimir mostrando todos os dados */}
          <style type="text/css" media="print">
            {'\
             @page { size: landscape; }\
            '}
          </style>
          {/* ------------------------------------------------------------>	 */}
          <TableContainer>
            <Table className={classes.table} aria-label="customized table">
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {data?.items.map((ticket) => (
                  <TableRow hover key={ticket.id}>
                    <TableCell component="th" scope="ticket" align="left">
                      {ticket.id}
                    </TableCell>
                    <TableCell align="center">
                      <div className={classes.nameCell}>
                        <Avatar
                          className={classes.avatar}
                          src={
                            getImageUrls(ticket?.usuario?.imagem)?.thumbnailUrl
                          }
                        >
                          {getInitials(ticket?.usuario?.nome)}
                        </Avatar>
                        <div>
                          {ticket?.usuario?.nome}
                          <div>{ticket?.usuario?.email}</div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {cpfCnpjMask(ticket.usuario.cpfCnpj)}
                    </TableCell>
                    <TableCell align="center">
                      {phoneMask(ticket.usuario.telefone)}
                    </TableCell>
                    <TableCell align="center">
                      {ticket.lote.tipoIngresso.descricao} - (
                      {ticket.lote.descricao})
                    </TableCell>
                    <TableCell align="center">
                      {formatCurrency(ticket.valor - ticket.valorDesconto)}
                    </TableCell>
                    <TableCell align="right">
                      <div className={classes.statusCell}>
                        <Label
                          color={getStatusIngressoColor(ticket.statusIngresso)}
                          variant="outlined"
                        >
                          {ticket.statusIngresso.descricao}
                        </Label>
                        <div>
                          {ticket?.dataHoraValidacao ? (
                            <Typography variant="caption">
                              Validado em:{' '}
                              {toLocalDateFormat(ticket?.dataHoraValidacao)}
                            </Typography>
                          ) : null}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[
              5,
              15,
              25,
              50,
              100,
              { label: 'Todos', value: 9999 }
            ]}
            component="div"
            count={data?.totalRows}
            rowsPerPage={Number(querySearch.get('limit')) || 15}
            page={Number(querySearch.get('page')) || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </Box>
  )
}

export default TableIngressos
